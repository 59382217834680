import { issueDrawerTabsList } from '../../issues/active-issues/issue-drawer-tabs-list';
import { PageIconsByDisplayName } from '../../menu/config/main-menu-config';
import { PageSetting } from '../types';

export const resolvePageIcon = (item: PageSetting, parent?: string) => {
  switch (parent) {
    case 'Issues':
      return item.iconKey && issueDrawerTabsList[item.iconKey]?.icon;
    default:
      return PageIconsByDisplayName[item.page];
  }
};

export const isViewEnabled = (page: string, preferences: PageSetting[]) => {
  const setting = preferences.find(item => item.page === page);
  if (!setting) return true;
  return Boolean(setting.value === true);
};
