import { snapshot } from 'valtio';
import viewPreferencesService from '../services';
import UserViewPreferencesStore from '../stores/user-view-preference-store';
import { PageSetting } from '../types';
import { setUserViewPreferences } from '../stores-actions/view-preferences-store-actions';

const loadUserViewSettings = async () => {
  const settings = await viewPreferencesService.getUserViewSettings.execute();
  if (settings) {
    setUserViewPreferences(settings);
  }
};

const enableTabSettingLocally = (tabName: string) => {
  const { preferences } = snapshot(UserViewPreferencesStore);
  const newPreferences = preferences.map(item => ({
    ...item,
    children: item.children?.map(child => {
      if (child.page === tabName) {
        return { ...child, value: true };
      }
      return child;
    }),
  }));

  setUserViewPreferences(newPreferences);
};

const getAvailableContentPerPage = (
  page: string,
  preferences: PageSetting[],
) => {
  const contents = preferences?.find(view => view.page === page)?.children;
  const tabsWithAggregations = contents?.reduce((acc, content) => {
    if (content.aggregation?.length) {
      return acc.concat(
        content.aggregation.map(agg => ({
          ...content,
          children: [],
          page: agg,
        })),
      );
    }
    return acc.concat(content);
  }, [] as PageSetting[]);
  return tabsWithAggregations;
};

const filterTabsByPreferences = (
  tabItems: { title: string }[],
  preferences: PageSetting[],
  page: string,
) => {
  const avalableTabs = getAvailableContentPerPage(page, preferences);
  return tabItems?.filter(tab => {
    const item = avalableTabs?.find(t => t.page === tab.title);
    if (!item) return true;

    return item.value;
  });
};

export const viewPreferencesActions = {
  loadUserViewSettings,
  enableTabSettingLocally,
  filterTabsByPreferences,
};
