import { OxCategoriesKeys } from '@oxappsec/ox-consolidated-categories';
import { logger } from 'logging-utils';
import { IconType, Nullable } from 'ox-common-types';
import { SiCircleci, SiGithubactions } from 'react-icons/si';
import { snapshot, useSnapshot } from 'valtio';
import { ReactComponent as CheckovLogo } from '../../assets/icons/checkov-logo.svg';
import { ReactComponent as DevSkimLogo } from '../../assets/icons/devskim-logo.svg';
import { ReactComponent as GithubLogo } from '../../assets/icons/github.svg';
import { ReactComponent as BlackduckLogo } from '../../assets/icons/black-duck-icon.svg';
import { ReactComponent as GitLeaksLogo } from '../../assets/icons/gitleaks-logo.svg';
import { ReactComponent as LicenseFinderLogo } from '../../assets/icons/license-icon.svg';
import { ReactComponent as OrcaLogo } from '../../assets/icons/orca-logo.svg';
import { ReactComponent as ProwlerLogo } from '../../assets/icons/prowler-logo.svg';
import { ReactComponent as SecretLintLogo } from '../../assets/icons/secretlint-logo.svg';
import { ReactComponent as CoverityLogo } from '../../assets/icons/coverity.svg';
import { ReactComponent as OxCodeSecurityLogo } from '../../assets/icons/ox-code-security.svg';
import { ReactComponent as OxSecretScanLogo } from '../../assets/icons/ox-secret-scan.svg';
import { ReactComponent as OxScaLogo } from '../../assets/icons/ox-sca.svg';
import { ReactComponent as OxSbomLogo } from '../../assets/icons/ox-sbom.svg';
import { ReactComponent as OxIacLogo } from '../../assets/icons/ox-iac.svg';
import { ReactComponent as OxContainersLogo } from '../../assets/icons/ox-containers.svg';
import { ReactComponent as OxCloudSecLogo } from '../../assets/icons/ox-cloud-sec.svg';
import { ReactComponent as OxGitPostureLogo } from '../../assets/icons/ox-git-posture.svg';
import { ReactComponent as OxCICDPostureLogo } from '../../assets/icons/ox-cicd-posture.svg';
import { BadgeStatusType } from '../../supply-chain/types/badgeIcon.types';
import {
  Connector,
  ConnectorStatus,
  ConnectorStatusTitle,
  ConnectorsByFamilyObject,
} from '../connectors-types';
import ConnectorsStore from '../stores/connectors-store';
import { isTokenExpired } from './credentials-utils';

export const findAliasConnectorMatch = (
  aliasConnector: Connector,
  nonStoreConnectorsByFamily?: ConnectorsByFamilyObject[],
) => {
  if (!aliasConnector.aliasFor) {
    throw new Error('Connector supplied has no aliasFor field');
  }
  const matchedConnector = findConnectorByNameProperty(
    aliasConnector.aliasFor,
    nonStoreConnectorsByFamily,
  );
  if (!matchedConnector) {
    logger.warn(
      `No matching connector was found for alias name: ${aliasConnector.aliasFor}`,
    );
  }
  return matchedConnector || aliasConnector;
};

export const findConnectorByNameProperty = (
  connectorName: string,
  nonStoreConnectorsByFamily?: ConnectorsByFamilyObject[],
) => {
  const { connectorsByFamily } = snapshot(ConnectorsStore);
  const connectorsByFamilyToUse =
    nonStoreConnectorsByFamily || connectorsByFamily;
  const matchedConnector: Nullable<Connector> = connectorsByFamilyToUse.reduce(
    (acc: Nullable<Connector>, familyObject) => {
      if (acc) {
        return acc;
      }
      const foundConnector = familyObject.connectors.find(
        c => c.name === connectorName,
      );
      if (!foundConnector) {
        return acc;
      }
      acc = foundConnector;
      return acc;
    },
    null,
  );
  return matchedConnector;
};

export const shouldDisableScanAll = (
  connectors: Connector[],
  isScanning: boolean,
) => {
  const areConnectorsEmpty = !connectors || !connectors.length;

  const configuredConnectorsFound = connectors.some(c => {
    if (c.family !== OxCategoriesKeys.SourceControl) {
      return false;
    }
    const isConnectorConfiguredNotByDefault =
      c.isConfigured && !c.defaultEnabled;
    const isConnectorValid = c.credentials.some(
      cred => !isTokenExpired(cred.tokenExpirationDate),
    );
    return (
      isConnectorConfiguredNotByDefault && isConnectorValid && !c.isEmptyOfRepos
    );
  });
  return isScanning || !configuredConnectorsFound || areConnectorsEmpty;
};

export const createConnectorStatus = (
  connector: Connector,
): ConnectorStatus => {
  const invalidToken = isTokenExpired(
    connector.credentials?.[0]?.tokenExpirationDate,
  );
  if (invalidToken) {
    return {
      title: ConnectorStatusTitle.ConnectivityIssue,
      color: 'connectionIssue',
    };
  }
  if (connector.isConfigured) {
    return { title: ConnectorStatusTitle.Connected, color: 'connected' };
  }
  return { title: ConnectorStatusTitle.NotConnected, color: 'notConnected' };
};

const svgLogosMap = new Map<string, IconType>([
  ['github', GithubLogo],
  ['github issues', GithubLogo],
  ['github secret detection', GithubLogo],
  ['github sast', GithubLogo],
  ['github actions', SiGithubactions],
  ['checkov', CheckovLogo],
  ['devskim', DevSkimLogo],
  ['gitleaks', GitLeaksLogo],
  ['secretlint', SecretLintLogo],
  ['orca', OrcaLogo],
  ['black duck', BlackduckLogo],
  ['prowler', ProwlerLogo],
  ['license finder', LicenseFinderLogo],
  ['circleci', SiCircleci],
  ['coverity', CoverityLogo],
  ['ox code security', OxCodeSecurityLogo],
  ['ox secret/pii scan', OxSecretScanLogo],
  ['ox open source security', OxScaLogo],
  ['ox sbom scan', OxSbomLogo],
  ['ox iac scan', OxIacLogo],
  ['ox container security', OxContainersLogo],
  ['ox cloud context', OxCloudSecLogo],
  ['ox git posture', OxGitPostureLogo],
  ['ox ci/cd posture', OxCICDPostureLogo],
]);

const overrideServerLogo = (connectorName: string) => {
  return svgLogosMap.get(connectorName.toLowerCase());
};

export const resolveConnectorIcon = (connectorName: string) => {
  const LocalLogo = overrideServerLogo(connectorName);
  return LocalLogo;
};

export const resolveBadgeStatus = (connector: Connector) => {
  const invalidToken = isTokenExpired(
    connector.credentials?.[0]?.tokenExpirationDate,
  );
  if (invalidToken) {
    return BadgeStatusType.ConnectionIssue;
  }
  if (connector.isConfigured) {
    return BadgeStatusType.Connected;
  }
  return BadgeStatusType.NotConnected;
};

export const useConfiguredConnectors = () => {
  const { connectorsByFamily } = useSnapshot(ConnectorsStore);
  return connectorsByFamily.reduce((acc: Connector[], family) => {
    acc.push(...family.connectors.filter(c => c.isConfigured));
    return acc;
  }, []);
};

export const findConnectivityIssues = (
  connectorsByFamily: ConnectorsByFamilyObject[],
) => {
  const connectivityIssues = connectorsByFamily.reduce(
    (acc: string[], connectorsByFamilyObj) => {
      const connectorsWithIssues = connectorsByFamilyObj.connectors.reduce(
        (acc: string[], connector) => {
          if (isTokenExpired(connector.credentials[0]?.tokenExpirationDate)) {
            acc.push(connector.displayName);
          }
          return acc;
        },
        [],
      );
      acc.push(...connectorsWithIssues);
      return acc;
    },
    [],
  );
  return connectivityIssues;
};
