import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import ConnectionsStore from '../stores/connections-store';
import SelectedConnectionStore from '../stores/slected-connection-store';
import { ConnectionType } from '../types/connection-types';

export const closeConnectionModal = (): void => {
  SelectedConnectionStore.selectedConnectionType = null;
};

export const openConnectionModal = (type: ConnectionType): void => {
  SelectedConnectionStore.selectedConnectionType = type;
};

export const updateConnectionState = (
  connectionId: string,
  enabled: boolean,
) => {
  const connections = ConnectionsStore.connections;

  const connection = connections.find(c => c.id === connectionId);
  if (connection) {
    connection.enabled = enabled;
  }

  ConnectionsStore.connections = connections;
};

export const setIsLoadingAdd = (isLoading: boolean) => {
  SelectedConnectionStore.isLoadingAdd = isLoading;
};

export const setIsLoadingRemove = (isLoading: boolean) => {
  SelectedConnectionStore.isLoadingRemove = isLoading;
};

export const getConnection = (type: Nullable<ConnectionType>) => {
  const { connections } = snapshot(ConnectionsStore);
  return connections.find(c => c.connectionType === type);
};

export const setLoggedInUserConnection = (userId: string | undefined): void => {
  if (!userId) {
    return;
  }

  const strategy = userId.split('|')[0];
  if (!strategy) {
    return;
  }

  SelectedConnectionStore.loggedInUserStrategy = strategy;
};
