import { Collapse, Divider, Theme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Location, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { MenuItemConfig } from '../config/main-menu-config';
import { AppMenuRawItem } from './AppMenuRawItem';
import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';
import { isItemHidden } from '../utils.ts/menu-utils';

const isActiveUrl = (location: Location, href: string): boolean => {
  const includeQueryString = href.includes('?');
  const currentUrl =
    location.pathname + (includeQueryString ? location.search : '');

  return currentUrl.startsWith(href);
};

export const AppMobileMenuItem: FC<AppMobileMenuItemProps> = ({
  href,
  icon: Icon,
  label,
  isHidden,
  badgeName,
  subItems,
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const flags = useOxFlags();

  const handleOpenSubMenu = useCallback(() => {
    setSubMenuOpen(true);
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setSubMenuOpen(false);
  }, []);

  if (isHidden) {
    return null;
  }

  const subMenuItemsFiltered = subItems?.filter(item => {
    return !isItemHidden(item, flags);
  });

  if (subItems && subMenuItemsFiltered && subMenuItemsFiltered.length > 1) {
    const isParentActive =
      isActiveUrl(location, href) ||
      (subItems.length > 0 &&
        subItems.some(item => isActiveUrl(location, item.href)));

    return (
      <>
        <div
          onClick={e => {
            e.stopPropagation();
            if (subMenuOpen) {
              handleCloseSubMenu();
            } else {
              handleOpenSubMenu();
            }
          }}
          className={classes.parent}>
          <AppMenuRawItem
            href={undefined}
            isActive={isParentActive}
            label={label}
            isOpen={subMenuOpen}
            Icon={Icon}
            aside={subMenuOpen ? <MdExpandLess /> : <MdExpandMore />}
          />
        </div>
        <Collapse orientation='vertical' in={subMenuOpen}>
          {subItems?.map(item => (
            <AppMobileMenuItem
              key={item.label}
              {...item}
              showTooltip={false}
              isHidden={isItemHidden(item, flags)}
            />
          ))}
        </Collapse>
        {subMenuOpen && (
          <Divider orientation='horizontal' sx={{ borderBlockEndWidth: 2 }} />
        )}
      </>
    );
  }

  const isActive = isActiveUrl(location, href);
  return (
    <AppMenuRawItem
      href={href!}
      isActive={isActive}
      label={label}
      isOpen={false}
      Icon={Icon}
      className={classes.parent}
    />
  );
};

interface AppMobileMenuItemProps extends MenuItemConfig {
  active?: boolean;
  showTooltip?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  parent: {
    height: 50,
    display: 'flex',
  },
}));
