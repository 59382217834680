import {
  Dialog,
  Divider,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiMenu } from 'react-icons/bi';
import { makeStyles } from 'tss-react/mui';
import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';
import {
  PrimaryMenuItems,
  secondaryMenuItems,
} from '../config/main-menu-config';
import { AppMobileMenuItem } from './AppMobileMenuItem';
import { isItemHidden } from '../utils.ts/menu-utils';
import { useSnapshot } from 'valtio';
import UserViewPreferencesStore from '../../view-preferences/stores/user-view-preference-store';
import { isViewEnabled } from '../../view-preferences/utils/view-preferences-utils';

export const AppMobileMenu: FC = () => {
  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const flags = useOxFlags();
  const { viewPreferencesEnabled } = flags;
  const { preferences } = useSnapshot(UserViewPreferencesStore);
  const theme = useTheme();
  const matchesBigScreen = useMediaQuery(theme.breakpoints.up('md'));

  if (matchesBigScreen) {
    return null;
  }

  if (preferences?.length) {
    return (
      <>
        <Tooltip title='Open Menu' arrow>
          <IconButton onClick={() => setDialogOpen(true)} color='inherit'>
            <BiMenu />
          </IconButton>
        </Tooltip>
        <Dialog
          scroll='body'
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}>
          <div>
            <div className={classes.header}>
              <Typography variant='body1' className={classes.grow}>
                Main Menu
              </Typography>
              <IconButton size='small' onClick={() => setDialogOpen(false)}>
                <AiOutlineClose />
              </IconButton>
            </div>
            <Divider orientation='horizontal' />
            <div onClick={() => setDialogOpen(false)} className={classes.list}>
              {PrimaryMenuItems.map(
                item =>
                  (viewPreferencesEnabled
                    ? isViewEnabled(item.label, preferences)
                    : true) && (
                    <AppMobileMenuItem
                      key={item.label}
                      {...item}
                      isHidden={isItemHidden(item, flags)}
                      badgeName={
                        item.badgeName !== undefined
                          ? item.featureFlagBadgeKey !== undefined &&
                            flags[item.featureFlagBadgeKey]
                            ? ''
                            : item.badgeName
                          : ''
                      }
                    />
                  ),
              )}

              <div style={{ flex: '1 1 20px' }}></div>

              {secondaryMenuItems.map(item => (
                <AppMobileMenuItem
                  key={item.label}
                  {...item}
                  isHidden={isItemHidden(item, flags)}
                  badgeName={
                    item.badgeName !== undefined
                      ? item.featureFlagBadgeKey !== undefined &&
                        flags[item.featureFlagBadgeKey]
                        ? ''
                        : item.badgeName
                      : ''
                  }
                />
              ))}
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  return null;
};

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingInlineStart: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  list: {
    paddingBlock: theme.spacing(1),
  },
}));
