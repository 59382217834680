import { orgManagementClient, reportClient, tagsClient } from 'api-clients';
import addUserConsent from './add-user-consent/add-user-consent';
import auditUserLogin from './audit-user-login/audit-user-login';
import getOrgStatus from './get-org-status/get-org-status';
import updateAppOwnerName from './update-app-owner-name/update-app-owner-name';
import deleteAppOwner from './delete-app-owner/delete-app-owner';
import updateAppOwnerEmail from './update-app-owner-email/update-app-owner-email';
import updateAppOwnerV2 from './update-app-owner-v2/update-app-owner';
import deleteAppOwnerV2 from './delete-app-owner-v2/delete-app-owner';

const organizationService = {
  addUserConsent: addUserConsent(orgManagementClient),
  auditUserLogin: auditUserLogin(orgManagementClient),
  getOrgStatus: getOrgStatus(reportClient),
  updateAppOwnerName: updateAppOwnerName(reportClient),
  deleteAppOwner: deleteAppOwner(reportClient),
  updateAppOwnerEmail: updateAppOwnerEmail(reportClient),
  deleteAppOwnerV2: deleteAppOwnerV2(tagsClient),
  updateAppOwnerV2: updateAppOwnerV2(tagsClient),
};

export default organizationService;
