import { FC, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import useIssuesActions from '../../../../../hooks/use-issues-actions';
import { IssueAction } from '../../../../../types/issues-types';

const ids = [
  'view-code-fix',
  'open-pr',
  'send-slack-message',
  'create-jira-ticket',
];

const DevSummaryActions: FC = () => {
  const allActions = useIssuesActions();
  const actions = useMemo(
    () =>
      allActions
        .flat()
        .reduce((acc, action) => {
          acc.push(action);
          if (Array.isArray(action.subMenuActions)) {
            acc.push(...action.subMenuActions);
          }
          return acc;
        }, [] as IssueAction[])
        .filter(action => ids.includes(action.actionId))
        .sort((a, b) => ids.indexOf(a.actionId) - ids.indexOf(b.actionId)),
    [allActions],
  );

  return (
    <Box display='flex' flexDirection='row' gap={1} flexWrap='wrap'>
      {actions.map(action => {
        const Icon = action.icon;
        return (
          !action.disabled && (
            <Button
              onClick={action.onClick}
              variant='outlined'
              sx={{ textTransform: 'none', height: 30 }}
              startIcon={Icon && <Icon />}>
              {action.title}
            </Button>
          )
        );
      })}
    </Box>
  );
};

export default DevSummaryActions;
