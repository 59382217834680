import { Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';
import DrawerStore from '../../app/stores/drawer-store';
import { useOxFlags } from '../../feature-flags/api/feature-flags-api';
import {
  secondaryMenuItems,
  PrimaryMenuItems,
} from '../config/main-menu-config';
import { AppMenuItem } from './AppMenuItem';
import { CollapseButton } from './CollapseButton';
import { isItemHidden } from '../utils.ts/menu-utils';

import UserViewPreferencesStore from '../../view-preferences/stores/user-view-preference-store';
import { isViewEnabled } from '../../view-preferences/utils/view-preferences-utils';

const COLLAPSED_SIZE = 56;
const EXPANDED_SIZE = 150;

export const AppMenu: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const { open } = useSnapshot(DrawerStore);
  const flags = useOxFlags();
  const { viewPreferencesEnabled } = flags;
  const { preferences } = useSnapshot(UserViewPreferencesStore);

  if (preferences?.length) {
    return (
      <div
        className={classes.appMenu}
        style={{ width: open ? EXPANDED_SIZE : COLLAPSED_SIZE }}>
        {PrimaryMenuItems.map(
          item =>
            (viewPreferencesEnabled
              ? isViewEnabled(item.label, preferences)
              : true) && (
              <AppMenuItem
                key={item.label}
                {...item}
                isHidden={isItemHidden(item, flags)}
                badgeName={
                  item.badgeName !== undefined
                    ? item.featureFlagBadgeKey !== undefined &&
                      flags[item.featureFlagBadgeKey]
                      ? ''
                      : item.badgeName
                    : ''
                }
              />
            ),
        )}

        <div style={{ flex: '1 1 20px' }}></div>

        {secondaryMenuItems.map(item => (
          <AppMenuItem
            key={item.label}
            {...item}
            isHidden={isItemHidden(item, flags)}
            badgeName={
              item.badgeName !== undefined
                ? item.featureFlagBadgeKey !== undefined &&
                  flags[item.featureFlagBadgeKey]
                  ? ''
                  : item.badgeName
                : ''
            }
          />
        ))}

        <CollapseButton />
      </div>
    );
  }
  return null;
};
const useStyles = makeStyles()((theme: Theme) => ({
  appMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingBlock: theme.spacing(1),
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: `text.${theme.palette.mode === 'dark' ? 'primary' : 'secondary'}`,
    transition: theme.transitions.create('width'),
  },
}));
