import { Theme, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { MenuItemConfig } from '../config/main-menu-config';
import { AppMenuRawItem } from './AppMenuRawItem';
import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';
import { isItemHidden } from '../utils.ts/menu-utils';

const isActiveUrl = (location: Location, href: string): boolean => {
  const includeQueryString = href.includes('?');
  const currentUrl =
    location.pathname + (includeQueryString ? location.search : '');

  return currentUrl.startsWith(href);
};

export const AppMenuItem: FC<AppMenuItemProps> = ({
  href,
  icon: Icon,
  label: title,
  showTooltip = true,
  isHidden,
  badgeName,
  subItems,
}) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const flags = useOxFlags();

  const handleOpenSubMenu = useCallback(() => {
    setSubMenuOpen(true);
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setSubMenuOpen(false);
  }, []);

  if (isHidden) {
    return null;
  }

  const subMenuItemsFiltered = subItems?.filter(item => {
    return !isItemHidden(item, flags);
  });

  if (subItems && subMenuItemsFiltered && subMenuItemsFiltered.length > 1) {
    const isParentActive =
      isActiveUrl(location, href) ||
      (subItems.length > 0 &&
        subItems.some(item => isActiveUrl(location, item.href)));

    return (
      <div
        style={{ width: '100%' }}
        onMouseEnter={handleOpenSubMenu}
        onMouseLeave={handleCloseSubMenu}>
        <Tooltip
          placement='right-start'
          classes={{
            tooltip: classes.noPadding,
          }}
          arrow
          open={subMenuOpen}
          disableHoverListener
          title={
            <div onClick={handleCloseSubMenu}>
              {subItems?.map(item => (
                <AppMenuItem
                  key={item.label}
                  {...item}
                  showTooltip={false}
                  isHidden={isItemHidden(item, flags)}
                />
              ))}
            </div>
          }>
          <div style={{ width: '100%', height: '100%' }}>
            <AppMenuRawItem
              href={href!}
              isActive={isParentActive}
              label={title}
              isOpen={subMenuOpen}
              Icon={Icon}
              badge={badgeName}
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  const isActive = isActiveUrl(location, href);
  return !showTooltip ? (
    <AppMenuRawItem
      href={href!}
      isActive={isActive}
      label={title}
      isOpen={false}
      Icon={Icon}
    />
  ) : (
    <Tooltip
      title={
        <Typography
          variant='caption'
          color='text.primary'>{`${title} Page`}</Typography>
      }
      arrow
      placement='right'>
      <div style={{ width: '100%' }}>
        <AppMenuRawItem
          href={href!}
          isActive={isActive}
          label={title}
          isOpen={false}
          Icon={Icon}
        />
      </div>
    </Tooltip>
  );
};

interface AppMenuItemProps extends MenuItemConfig {
  active?: boolean;
  showTooltip?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  appMenuRawItem: {
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    color: 'inherit',
    height: 40,
  },
  noPadding: {
    padding: 0,
  },
}));
