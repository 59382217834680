import { DateUnit, IDateRange } from 'ox-common-types';
import { createStore } from 'store-utils';

const TopBarStore = createStore<TopBarState>(
  {
    dateRange: {
      limit: 1,
      unit: DateUnit.Week,
    },
    isLoadingTopBarActions: false,
  },
  'Top Bar Store',
);

export interface TopBarState {
  dateRange: IDateRange;
  isLoadingTopBarActions: boolean;
}

export default TopBarStore;
