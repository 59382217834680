import { createStore } from 'store-utils';
import { PageSetting } from '../types';

const UserViewPreferencesStore = createStore<UserViewPreferencesState>(
  {
    preferences: [],
  },
  'View Preferences Store',
);
export interface UserViewPreferencesState {
  preferences: PageSetting[];
}

export default UserViewPreferencesStore;
