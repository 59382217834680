import { isDevelopment } from 'env-utils';
import { FeatureFlags } from '../../feature-flags/feature-flags-types';
import { MenuItemConfig } from '../config/main-menu-config';

export const isItemHidden = (item: MenuItemConfig, flags: FeatureFlags) => {
  if (item.isDevelopment) {
    return !isDevelopment();
  }
  return item.featureFlagKey !== undefined
    ? !flags[item.featureFlagKey]
    : item.isHidden;
};
