import { RedirectLoginOptions } from '@auth0/auth0-react';
import {
  setAppGeneralError,
  setAppIsFirstLogin,
  setAppPageError,
} from 'app-store';
import { TokenStore } from 'common-auth';
import { AppEvents } from 'common-events';
import { isBefore } from 'date-fns';
import { env } from 'env-utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { logger } from 'logging-utils';
import { OrganizationsStore } from 'organizations';
import { useKeyPress } from 'react-utils';
import { snapshot } from 'valtio';
import { Organization } from '../../organizations/organization-types';
import { getLastLoggedInOrg } from '../../organizations/utils/local-storage-utils';
import { Location } from 'history';
import {
  BITBUCKET_SESSION_REDIRECT_TO_KEY,
  BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING,
} from 'ox-common-types';

export const setLoadingNewToken = (isLoading: boolean): void => {
  TokenStore.loadingNewToken = isLoading;
};

export const setGeneralError = (hasError: boolean) => {
  setAppGeneralError(hasError);
};

export const setPageError = (hasError: boolean) => {
  setAppPageError(hasError);
};

export const setShouldForceLogout = (forceLogout: boolean) => {
  TokenStore.shouldForceLogout = forceLogout;
};

export const getAuthToken = () => {
  const { authToken } = snapshot(TokenStore);
  const { chosenOrganization } = snapshot(OrganizationsStore);
  const lastLoggedInOrg = getLastLoggedInOrg();
  if (chosenOrganization?.id !== lastLoggedInOrg?.id) {
    logger.error(
      'Request scoped to the last logged in organization, please refresh the page and try again.',
    );
    setShouldForceLogout(true);
    return;
  }

  if (!authToken) {
    throw new Error(
      'Tried to get auth token before a token was fetched or saved',
    );
  }
  try {
    const decodedToken = jwtDecode<JwtPayload>(authToken);
    if (!decodedToken.exp) {
      throw new Error('An auth token without exp field is saved in the store');
    }

    const isExpired = isBefore(new Date(decodedToken.exp * 1000), new Date());
    if (isExpired) {
      setShouldForceLogout(true);
    }
  } catch (err) {
    logger.error('Invalid auth token, could not check its validity');
    throw err;
  }

  return authToken;
};

// fetching organizations, token, check if need to onboard
export const logUserIntoOrganization = async (
  isAuthenticated: boolean,
  organizations: Organization[],
  getAccessTokenSilently: () => Promise<string>,
  getAvailableOrganizations: () => Promise<Organization[] | undefined>,
  fetchOrgInfo: () => Promise<void>,
) => {
  if (isAuthenticated && organizations.length === 0) {
    setLoadingNewToken(true);
    const token = await getAccessTokenSilently();
    TokenStore.authToken = token;
    setLoadingNewToken(false);
    const organizations = await getAvailableOrganizations();
    const isFirstLogin = !!organizations && organizations.length === 0;
    await fetchOrgInfo();

    // set to true in order to start the onboarding process
    setAppIsFirstLogin(isFirstLogin);
    // return true if first login
    return isFirstLogin;
  }
};

export const loginUser = (loginFunction: LoginFunction) => {
  loginFunction(createLoginArgs());
};

type LoginFunction = (options?: RedirectLoginOptions | undefined) => void;

const createLoginArgs = () => {
  const { chosenOrganization } = snapshot(OrganizationsStore);
  if (chosenOrganization) {
    return {
      organization: chosenOrganization.id,
    };
  }
  return undefined;
};

export const dispatchIdpCallbackEvent = (searchParams: URLSearchParams) => {
  document.dispatchEvent(
    new CustomEvent<URLSearchParams>(
      AppEvents.Connectors.IdentityProviderCallback,
      {
        detail: searchParams,
      },
    ),
  );
};

export const dispatchGitHubAppInstallationCallbackEvent = (
  location: Location,
) => {
  document.dispatchEvent(
    new CustomEvent<Location>(
      AppEvents.Connectors.GitHubAppInstallationCallback,
      {
        detail: location,
      },
    ),
  );
};

export const dispatchBitbucketAppInstallationCallbackEvent = (
  searchParams: URLSearchParams,
) => {
  if (
    window.sessionStorage.getItem(BITBUCKET_SESSION_REDIRECT_TO_KEY) ===
    BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING
  ) {
    window.sessionStorage.removeItem(BITBUCKET_SESSION_REDIRECT_TO_KEY);
    searchParams.set(
      'state',
      encodeURIComponent(JSON.stringify({ onboarding: true })),
    );
  }

  document.dispatchEvent(
    new CustomEvent<URLSearchParams>(
      AppEvents.Connectors.BitbucketAppInstallationCallback,
      {
        detail: searchParams,
      },
    ),
  );
};

export const useDebugToggle = () => {
  const isShiftPressed = useKeyPress('Shift');
  const isDPressed = useKeyPress('D');
  if (isShiftPressed && isDPressed) {
    const isDebug = !!env()['isDebug'];
    // eslint-disable-next-line no-console
    console.log(isDebug ? 'Debug mode off' : 'Debug mode on');
    window['env']['isDebug'] = !isDebug;
  }
};
