import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './update-app-owner.gql';
import { logger } from 'logging-utils';
import { OxTagType } from '../../../applications/applications-types';

const updateAppOwnerV2 = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: UpdateTagInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<UpdateTagRes>({
          mutation,
          fetchPolicy,
          variables: { input },
        });

        return results.data?.updateTag.acknowledge ?? false;
      } catch (error) {
        logger.error('Failed to update app owner email', error);
        return false;
      }
    },
  };
};

export interface UpdateTagRes {
  updateTag: Acknowledge;
}
export interface Acknowledge {
  acknowledge: boolean;
}

export interface UpdateTagInput {
  tagId: string;
  tagType: OxTagType;
  tag: UpdateTagInputDTO;
}

export interface UpdateTagInputDTO {
  email?: string;
  name?: string;
  children?: string[];
}

export default updateAppOwnerV2;
