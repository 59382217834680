import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './delete-app-owner.gql';
import { logger } from 'logging-utils';
import { Acknowledge } from '../update-app-owner-v2/update-app-owner';

const deleteAppOwnerV2 = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: DeleteTagsInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<RemoveTagsRes>({
          mutation,
          fetchPolicy,
          variables: {
            deleteTagsInput: input,
          },
        });

        return results.data?.removeTags.acknowledge ?? false;
      } catch (error) {
        logger.error('Failed to delete app owner', error);
        return false;
      }
    },
  };
};

export interface RemoveTagsRes {
  removeTags: Acknowledge;
}

export interface DeleteTagsFilter {
  tagId?: string[] | string;
}

export interface DeleteTagsInput {
  filters: DeleteTagsFilter;
}

export default deleteAppOwnerV2;
