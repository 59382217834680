import { createStore, derive } from 'store-utils';
import { ActiveMember } from '../types/members-types';

const baseStore = createStore<MembersStoreState>(
  {
    activeMembers: [],
    isLoading: false,
    searchInput: '',
  },
  'Members Store',
);

const MembersStore = derive(
  {
    membersFiltered: get => {
      const { activeMembers, searchInput } = get(baseStore);
      const lowerCaseValue = searchInput.toLowerCase();
      return activeMembers.filter(member => {
        return (
          member.name?.toLowerCase().includes(lowerCaseValue) ||
          member.email?.toLowerCase().includes(lowerCaseValue) ||
          member.roles?.some(role =>
            role.name.toLowerCase().includes(lowerCaseValue),
          )
        );
      });
    },
  },
  { proxy: baseStore },
);

interface MembersStoreState {
  activeMembers: ActiveMember[];
  isLoading: boolean;
  searchInput: string;
}

export default MembersStore;
